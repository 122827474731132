.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
}

input {
  width: 100%;
  margin: 24px 0;
  font-size: large;
  border: 1px solid grey;
  border-radius: 4px;
  padding: 8px;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  padding-top: 120px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  gap: 4px;
}

.result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.result {
  width: 200px;
  text-align: center;
  padding: 40px;
  font-size: xx-large;
  border: 2px dashed black;
  margin: 24px;
}

.empty-container {
  text-align: center;
  padding: 24px;
}

footer {
  text-align: center;
  padding-bottom: 40px;
  font-size: x-small;
}