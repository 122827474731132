button {
  width: 200px;
  font-size: medium;
  background-color: transparent;
  border-radius: 4px;
  padding: 8px;
  height: 60px;
  color: black;
  font-size: small;
  font-weight: bold;
  border: 2px solid black;
}

button:disabled {
  border-color: #cccccc;
  color: #bbb;
}